@import url("https://fonts.googleapis.com/css2?family=poppins:weight@400;600&display=swap");
*{
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box ;
    font-family: "poppins, sans-serif";
}
/* main body section style */
.main-content{
    position: relative;
    background: #ebe9e9;
    width: 100%;
    padding: 1rem;
}

.header-wrapper img{
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
}
.header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 10px;
    padding: 10px 2rem;
    margin-bottom: 1rem;
}
.header-title{
    color: rgba(113, 99, 186, 255);
}
.user-info{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.search-box{
    background: rgb(237, 237, 237);
    border-radius: 15px;
    color: rgba(113, 99, 186, 255);
    display: flex;
    align-items: center;
    gap: 5px;
     padding: 4px 12px;
    }
    .search-box input{
        background: transparent;
        padding: 10px;
    }
    .search-box i{
        font-size: 1.2rem;
        cursor: pointer;
        transition: all 0.5s ease-out;
    }
    .search-box i:hover{
        transform: scale(1.2);
    }
 /********** card container  ******** */
 
 .card-container{
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
 }
.card-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
} 
.main-title{
    color: rgba(113, 99, 186, 255);
    padding-bottom: 10px;
    font-size: 15px;
}
.payment-card{
   background: rgb(229, 223, 223);
   border-radius: 10px;
   padding: 1.2rem;
   width: 290px;
   height: 150px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   transition: all 0.5s ease-in-out;
}
.payment-card:hover {
    transform: translate(-5px);
}
.card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.amount{
    display: flex;
    flex-direction: column;
}
.title{
    font-size: 12px;
    font-weight: 200;
}
.amount-value{
    font-size: 24px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
}
.icon{
    color: #fff;
    padding: 1rem;
    height: 60px;
    width: 60px;
    text-align: center;
    border-radius: 50%;
    font-size: 1.5rem;
    background: red;
}
.card-detail{
    font-size: 18px;
    color: #777777;
    letter-spacing: 2px;
    font-family: 'Courier New', Courier, monospace;
}